/*
Renders title and error messages for each field.
Provides wrapper components for common BlueprintJS inputs like DateInput, TextArea .
Handles rendering of generic inputs like text, number .
Renders custom components like TagSearch, ApiSearch, InputRange .
Handles input change and validation for certain inputs like NumericInput.
Allows configuring form fields via config prop - each field can specify its type, props etc.
Renders submit button using LocalizedButton.
*/
import React, { Component } from "react";
import {
  TextArea,
  Intent,
  RadioGroup,
  Radio,
  Menu,
  MenuItem,
  FormGroup,
  Popover,
  NumericInput,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { DateInput } from "@blueprintjs/datetime";
import { isNumber, isNaN, isArray } from "lodash";
import { SIZE, BUTTON_TYPE, BaseButton } from "prefab";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "../../styles/Form.module.scss";
import InputStyles from "../../styles/TextInput.module.scss";
import { FORM_FIELD_TYPE } from "../../constants";
import { formatDate, parseDate } from "../../utils/DatePickerUtil";
import TagSearch from "../common/Filters/TagSearch";
import { MAX_DATE, MIN_DATE } from "../../constants";
import Input from "./TextInput";
import Selector from "./Selector";
import Suggestion from "./Suggestion";
import BPSuggest from "./BPSuggest";
import ApiSearch from "./ApiSearch";
import ApiSelect from "./ApiSelect";
import InputWithSelect from "./InputWithSelect";
import MultiSelector from "./MultiSelect";
import Checkbox from "./Checkbox";
import DateRangePicker from "./DateRangePicker";
import InputTimePicker from "./InputTimePicker";
import InputHours from "./InputHours";
import InputRange from "./InputRange";
import DimensionsRangeInput from "./DimensionsRangeInput";
import Card from "./InfoBlock/Card";
import LocalizedButton from "./LocalizedButton";
import CustomDateRangePicker from "./CustomDateRangePicker";

// TODO: This component has grown too large, seems best to split into different components,
// instead of adding more inner render functions :(
class Form extends Component {
  static propTypes = {
    config: PropTypes.array.isRequired,
  };

  renderTitle = (title, isOptional = false) => (
    <div className={styles.title}>
      {title}
      {isOptional && <span>(Optional)</span>}
    </div>
  );

  renderError = (error, errorMessage) =>
    error && <div className={styles.errorMessage}>{errorMessage}</div>;

  renderInput = (props, i) => {
    const { className, inputType = "text", ...rest } = props;
    return (
      <Input key={i} type={inputType} className={classNames(styles.input, className)} {...rest} />
    );
  };

  renderSelector = (props, i) => {
    const { error, title, errorMessage, isOptional = false, className, ...rest } = props;
    return (
      <div className={classNames(className)} key={i}>
        {title && this.renderTitle(title, isOptional)}
        <Selector key={i} error={error} {...rest} />
        {this.renderError(error, errorMessage)}
      </div>
    );
  };

  renderSuggestion = (props, i) => {
    const { title, errorMessage, ...rest } = props;
    return (
      <div key={i}>
        {this.renderTitle(title)}
        <Suggestion key={i} {...rest} />
        {this.renderError(props.error, errorMessage)}
      </div>
    );
  };

  renderBPSuggest = (props, i) => {
    const { title, errorMessage, ...rest } = props;
    return (
      <div key={i}>
        {this.renderTitle(title)}
        <BPSuggest key={i} {...rest} />
        {this.renderError(props.error, errorMessage)}
      </div>
    );
  };

  renderLabel = (props, i) => {
    const { value, label, title, onClick = null } = props;

    return (
      <div className="col-12" key={i} onClick={onClick}>
        <div className={styles.title}>{label || title}</div>
        <div className={`${styles.value} ${styles.label}`}>{value}</div>
      </div>
    );
  };

  renderTextArea = (props, i) => {
    const { error, title, errorMessage, isOptional, className, ...rest } = props;
    return (
      <div className={classNames(className, className ? className : "col-11")} key={i}>
        {this.renderTitle(title, isOptional)}
        <TextArea
          className={styles.textArea}
          rows="10"
          fill
          large
          intent={error ? Intent.DANGER : Intent.NONE}
          {...rest}
        />
        {this.renderError(error, errorMessage)}
      </div>
    );
  };

  renderClearIcon = (props) => {
    if (!props.showClearIcon) return null;

    return (
      <BaseButton
        className={InputStyles.clearSelection}
        iconName="CancelRoundedIcon"
        buttonSize={SIZE.REGULAR}
        buttonType={BUTTON_TYPE.SECONDARY}
        onClick={props.onClear}
      />
    );
  };

  renderDate = (props, i) => {
    const {
      error,
      title,
      errorMessage,
      value,
      onChange,
      timePrecision,
      minDate,
      maxDate,
      disabled,
      defaultValue = new Date(),
      initialMonth = null,
      placeholder = "DD/MM/YYYY",
    } = props;
    return (
      <div key={i}>
        {this.renderTitle(title)}
        <DateInput
          className={classNames(InputStyles.inputField, { [InputStyles.error]: error })}
          defaultValue={defaultValue}
          initialMonth={initialMonth}
          disabled={disabled}
          formatDate={formatDate}
          onChange={onChange}
          popoverProps={{ usePortal: false }}
          parseDate={parseDate}
          placeholder={placeholder}
          value={value}
          timePrecision={timePrecision}
          minDate={minDate || new Date(MIN_DATE)}
          maxDate={maxDate || new Date(MAX_DATE)}
          rightElement={this.renderClearIcon(props)}
          highlightCurrentDay={true}
        />
        {this.renderError(error, errorMessage)}
      </div>
    );
  };

  renderRadioButtonGroup = (props, i) => {
    const { title, value, onChange, radioList, disabled } = props;
    return (
      <div key={i}>
        {this.renderTitle(title)}
        <RadioGroup
          disabled={disabled}
          inline={true}
          className={styles.radioGroup}
          onChange={(e) => onChange(e.target.value)}
          selectedValue={value}
        >
          {radioList.map((r, i) => (
            <Radio key={i} className={styles.radioButton} label={r.label} value={r.value} />
          ))}
        </RadioGroup>
      </div>
    );
  };

  renderMenu = (options, textKey, compareKey, value, onChange) => {
    return (
      <Menu>
        {options.map((item, index) => (
          <MenuItem
            key={index}
            text={item[textKey]}
            icon={value === item[compareKey] ? IconNames.SMALL_TICK : ""}
            onClick={() => onChange(item)}
          />
        ))}
      </Menu>
    );
  };

  renderDropDown = (props, i) => {
    const {
      title,
      error,
      errorMessage,
      value,
      displayText,
      textKey,
      compareKey,
      onChange,
      options,
    } = props;
    return (
      <div key={i}>
        <FormGroup
          label={title}
          helperText={error && <span>{errorMessage}</span>}
          intent={error ? Intent.DANGER : Intent.PRIMARY}
        >
          <Popover
            usePortal={false}
            content={this.renderMenu(options, textKey, compareKey, value, onChange)}
          >
            <BaseButton iconName="ChevronDownIcon" buttonText={displayText} />
          </Popover>
        </FormGroup>
      </div>
    );
  };

  renderTagSearch = (props, i) => {
    const { title, label, errorMessage, createNewLink, ...rest } = props;
    return (
      <div key={i}>
        <div className={styles.title}>{label || title}</div>
        <TagSearch {...rest} />
        {this.renderError(props.error, errorMessage)}
      </div>
    );
  };

  renderApiSearch = (props, i) => {
    const { title, label, errorMessage, createNewLink, ...rest } = props;
    return (
      <div key={i}>
        <div className={`${styles.title}`}>{label || title}</div>
        <ApiSearch {...rest} />
        {this.renderError(props.error, errorMessage)}
      </div>
    );
  };

  renderApiSelect = (props, i) => {
    const { title, className, label, errorMessage, ...rest } = props;
    return (
      <div key={i} className={className}>
        <div className={`${styles.title}`}>{label || title}</div>
        <ApiSelect {...rest} />
        {this.renderError(props.error, errorMessage)}
      </div>
    );
  };

  renderInputRange = (props, i) => {
    const { title, className, label, errorMessage, ...rest } = props;
    return (
      <div key={i} className={className}>
        <div className={`${styles.title}`}>{label || title}</div>
        <InputRange {...rest} />
        {this.renderError(props.error, errorMessage)}
      </div>
    );
  };

  renderDimensionsRangeInput = (props, i) => {
    const { title, className, label, errorMessage, ...rest } = props;
    return (
      <div key={i} className={className}>
        <div className={`${styles.title}`}>{label || title}</div>
        <DimensionsRangeInput {...rest} />
        {this.renderError(props.error, errorMessage)}
      </div>
    );
  };

  renderInputWithSelect = (props, i) => {
    return <InputWithSelect key={i} {...props} />;
  };

  renderMultiSelect = ({ onClick = null, ...props }, i) => {
    return (
      <div key={i} onClick={onClick}>
        <MultiSelector key={i} {...props} />
        {this.renderError(props.error, props.errorMessage)}
      </div>
    );
  };

  renderCheckBox = (props, i) => {
    const { title, checked, onChange, label, errorMessage, ...rest } = props;
    return (
      <div key={i}>
        {this.renderTitle(title)}
        <Checkbox checked={checked} onChange={onChange} label={label} {...rest} />
        {this.renderError(props.error, errorMessage)}
      </div>
    );
  };

  renderDateRangePicker = (props, i) => {
    const { title, errorMessage, containerClassName, className, ...rest } = props;
    return (
      <div key={i} className={className}>
        {this.renderTitle(title)}
        <DateRangePicker {...rest} error={props.error} />
        {this.renderError(props.error, errorMessage)}
      </div>
    );
  };

  renderTimeRangePicker = (props, i) => {
    const { title, errorMessage, ...rest } = props;
    return (
      <div key={i}>
        {this.renderTitle(title)}
        <InputTimePicker {...rest} />
        {this.renderError(props.error, errorMessage)}
      </div>
    );
  };

  renderInputHours = (props, i) => {
    const { title, errorMessage, ...rest } = props;
    return (
      <div key={i}>
        {this.renderTitle(title)}
        <InputHours {...rest} />
        {this.renderError(props.error, errorMessage)}
      </div>
    );
  };

  handleNumericInputChange = (value, props) => {
    if (props.onValueChange) {
      if (isNumber(value)) {
        props.onValueChange(value);
      }
    }
  };

  handleNumericInputKeyDown = (event, maxValue) => {
    const { value } = event.target;
    // Prevent further entering of values to avoid NaN, Infinity
    if (event.keyCode !== 8 && value.toString().length >= maxValue.toString().length) {
      event.preventDefault();
    }
  };

  handleNumericInputBlur = (event, props) => {
    const { value } = event.target;
    // Prevent further pasting the text to avoid NaN
    if (isNaN(parseInt(value))) {
      props.onValueChange(0);
    }
  };

  renderNumericInput = (props, i) => {
    const {
      buttonPosition = "none",
      title,
      className = InputStyles.numericInput,
      errorMessage,
      error,
      maxValue,
      isOptional,
      ...rest
    } = props;
    const inputMaxValue = maxValue ?? 999999999999999;
    return (
      <div key={i}>
        {title && this.renderTitle(title, isOptional)}
        <NumericInput
          {...rest}
          key={i}
          stepSize={1}
          buttonPosition={buttonPosition}
          className={classNames(className, { [InputStyles.error]: error })}
          max={inputMaxValue}
          min={rest.minValue ?? 0}
          clampValueOnBlur
          onValueChange={(value) => this.handleNumericInputChange(value, rest)}
          onBlur={(value) => this.handleNumericInputBlur(value, rest)}
          onKeyDown={(event) => this.handleNumericInputKeyDown(event, inputMaxValue)}
          // Prevent alphanumeric or string values directly
          onPaste={(event) => event.preventDefault()}
        />
        {this.renderError(error, errorMessage)}
      </div>
    );
  };

  renderButton = (fieldProps, i) => {
    const { isNonLocalizedButton = false, ...rest } = fieldProps;
    if (isNonLocalizedButton) {
      return <BaseButton key={i} {...rest} />;
    }
    return <LocalizedButton key={i} {...rest} />;
  };

  renderField = (c, i) => {
    const { type, is, ...fieldProps } = c;
    if (c.isLabel) return this.renderLabel(fieldProps, i);

    switch (type) {
      case FORM_FIELD_TYPE.INPUT:
        return this.renderInput(fieldProps, i);
      case FORM_FIELD_TYPE.SELECT:
        return this.renderSelector(fieldProps, i);
      case FORM_FIELD_TYPE.TEXT_AREA:
        return this.renderTextArea(fieldProps, i);
      case FORM_FIELD_TYPE.BUTTON:
        return this.renderButton(fieldProps, i);
      case FORM_FIELD_TYPE.SUGGESTION:
        return this.renderSuggestion(fieldProps, i);
      case FORM_FIELD_TYPE.BP_SUGGEST:
        return this.renderBPSuggest(fieldProps, i);
      case FORM_FIELD_TYPE.MULTI_SELECT:
        return this.renderMultiSelect(fieldProps, i);
      case FORM_FIELD_TYPE.LABEL:
        return this.renderLabel(fieldProps, i);
      case FORM_FIELD_TYPE.DATE:
        return this.renderDate(fieldProps, i);
      case FORM_FIELD_TYPE.RADIO:
        return this.renderRadioButtonGroup(fieldProps, i);
      case FORM_FIELD_TYPE.DROP_DOWN:
        return this.renderDropDown(fieldProps, i);
      case FORM_FIELD_TYPE.TAG_SEARCH:
        return this.renderTagSearch(fieldProps, i);
      case FORM_FIELD_TYPE.API_SEARCH:
        return this.renderApiSearch(fieldProps, i);
      case FORM_FIELD_TYPE.API_SELECT:
        return this.renderApiSelect(fieldProps, i);
      case FORM_FIELD_TYPE.INPUT_WITH_SELECT:
        return this.renderInputWithSelect(fieldProps, i);
      case FORM_FIELD_TYPE.CHECK_BOX:
        return this.renderCheckBox(fieldProps, i);
      case FORM_FIELD_TYPE.DATE_RANGE_PICKER:
        return this.renderDateRangePicker(fieldProps, i);
      case FORM_FIELD_TYPE.CUSTOM_DATE_RANGE_PICKER:
        return <CustomDateRangePicker key={i} {...fieldProps} />;
      case FORM_FIELD_TYPE.TIME_RANGE_PICKER:
        return this.renderTimeRangePicker(fieldProps, i);
      case FORM_FIELD_TYPE.INPUT_HOURS:
        return this.renderInputHours(fieldProps, i);
      case FORM_FIELD_TYPE.INPUT_RANGE:
        return this.renderInputRange(fieldProps, i);
      case FORM_FIELD_TYPE.DIMENSIONS:
        return this.renderDimensionsRangeInput(fieldProps, i);
      case FORM_FIELD_TYPE.NUMERIC_INPUT:
        return this.renderNumericInput(fieldProps, i);
      default:
        return null;
    }
  };

  getCardSize = (size) => {
    if (isNumber(size)) {
      return {
        lg: size,
        md: size,
        sm: size,
        xs: size,
      };
    }

    return size;
  };

  renderCardContainer = (card, index) => {
    if (card.isHidden) return null;
    const { isFormGroup } = this.props;
    const cardSize = this.getCardSize(card.size || 12);
    const { containerClassName, ...rest } = card;
    return (
      <Card
        key={index}
        className={classNames(containerClassName, {
          [styles.formgroup]: isFormGroup ?? true,
        })}
        {...cardSize}
      >
        {this.renderField(rest, index)}
      </Card>
    );
  };

  render() {
    const { config, childContainerClassName } = this.props;
    if (!config || config.length === 0) return null;

    return config.map((card, index) => {
      if (isArray(card)) {
        return (
          <div
            key={index}
            className={classNames(
              "col col-12 flex flex-wrap",
              styles.childContainer,
              childContainerClassName
            )}
          >
            {card.map((item, cIndex) => this.renderCardContainer(item, cIndex))}
          </div>
        );
      } else {
        return this.renderCardContainer(card, index);
      }
    });
  }
}

export default Form;
